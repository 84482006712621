import React from 'react';
import HeroButton from '../HeroButton/HeroButton';
import Button from '../Button/Button';

import '../Hero/Hero.scss';

function Hero() {


    return (
        <>
            <section id='hero' className='hero'>
                <article className='hero__cont'>
                    <div className='hero__sec'>

                        <div className='hero__info'>
                            <div className='hero__text'>
                                <h1 className='hero__title'>Brenda Gonzalez</h1>
                                <h2 className='hero__subtitle'>3D Modeler | Front-End Dev | Designer</h2>
                            </div>
                            <div className='hero__mobile--btn'>
                                <Button location={'#projects'} text='Projects' />
                            </div>
                            <div className='hero__desktop--btn'>
                                <HeroButton></HeroButton>
                            </div>
                        </div>
                    </div>
                </article>
                <div className='wave-bg'>
                    <div className="wave wave-fore">
                        <svg viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M941.2,71.5c-40-12.7-81.4-43.7-120.9-59.4C780-4,739.7-4,699.4,12.1c-39.5,15.7-80.9,46.7-120.9,59.4
	c-21,6.7-46.5,10-71.9,10c-25.4,0-50.9-3.3-71.9-10c-40-12.7-81.4-43.7-120.9-59.4C273.4-4,233.2-4,192.8,12.1
	c-39.5,15.7-80.9,46.7-120.9,59.4c-21,6.7-46.5,10-71.9,10V241h1013.1V81.4C987.8,81.4,962.3,78.2,941.2,71.5z" className="wave-fore-fill"></path>
                        </svg>
                    </div>
                    <div className="wave wave-mid">
                        <svg viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M941.2,71.5c-40-12.7-81.4-43.7-120.9-59.4C780-4,739.7-4,699.4,12.1c-39.5,15.7-80.9,46.7-120.9,59.4
	c-21,6.7-46.5,10-71.9,10c-25.4,0-50.9-3.3-71.9-10c-40-12.7-81.4-43.7-120.9-59.4C273.4-4,233.2-4,192.8,12.1
	c-39.5,15.7-80.9,46.7-120.9,59.4c-21,6.7-46.5,10-71.9,10V241h1013.1V81.4C987.8,81.4,962.3,78.2,941.2,71.5z" className="wave-mid-fill"></path>
                        </svg>
                    </div>
                    <div className="wave wave-back">
                        <svg viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M941.2,71.5c-40-12.7-81.4-43.7-120.9-59.4C780-4,739.7-4,699.4,12.1c-39.5,15.7-80.9,46.7-120.9,59.4
	c-21,6.7-46.5,10-71.9,10c-25.4,0-50.9-3.3-71.9-10c-40-12.7-81.4-43.7-120.9-59.4C273.4-4,233.2-4,192.8,12.1
	c-39.5,15.7-80.9,46.7-120.9,59.4c-21,6.7-46.5,10-71.9,10V241h1013.1V81.4C987.8,81.4,962.3,78.2,941.2,71.5z" className="wave-back-fill"></path>
                        </svg>
                    </div>
                </div>

            </section>
        </>
    );
}

export default Hero;




